import {
  Manufacturer,
  NormalizedSupplier,
  NormalizedSupplierCreateInput,
  NormalizedSupplierUpdateInput,
} from '@curvo/apollo'
import { Checkbox, Form, Input } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { LabeledValue } from 'antd/lib/select'
import { pick } from 'lodash'
import React, { useEffect, useState } from 'react'
import { EditDrawer } from '../../../../../components/EditDrawer'
import { EditPanelWrapper } from '../../../../../components/common'
import { ManufacturerSelect, MultipleManufacturerSelect } from '../Select/ManufacturerSelect'
import { NormalizedCurvoSuggestionsSelect } from '../Select/NormalizedCurvoSuggestionsSelect'
import { EditMode } from './EditManufacturer'

export const NormalizedSupplierDrawer: React.FC<
  {
    normalized?: NormalizedSupplier
    onSubmit: (input: NormalizedSupplierUpdateInput | NormalizedSupplierCreateInput) => void
    onCancel: () => void
    editMode: EditMode
  } & DrawerProps
> = ({ normalized, onSubmit, onCancel, editMode, ...drawerProps }) => {
  const [input, setInput] = useState<NormalizedSupplierCreateInput | NormalizedSupplierCreateInput>({
    inputSupplier: '',
  })

  useEffect(() => {
    if (normalized) {
      setInput(
        oldInput =>
          ({
            ...pick(normalized, [
              'inputSupplier',
              'type',
              'normalizedCurvo',
              'manufacturerId',
              'producesReprocessedItems',
            ]),
            inputSupplier: normalized.inputSupplier || oldInput.inputSupplier,
            manufacturerId: normalized.manufacturer ? normalized.manufacturer.id : undefined,
            retry: normalized.retry && normalized.retry.map(retry => pick(retry, ['id', 'name'])),
          } || oldInput),
      )
    } else {
      setInput({
        inputSupplier: '',
      })
    }
  }, [normalized])

  const typeRef = React.createRef<Input>()

  return (
    <EditDrawer
      onCancel={onCancel}
      onSave={() => {
        onSubmit(input)
        setInput({ inputSupplier: '' })
      }}
      {...drawerProps}>
      <EditPanelWrapper>
        <Form.Item label="Input Supplier">
          <Input
            disabled={editMode === EditMode.edit}
            value={input.inputSupplier}
            onChange={v => setInput({ ...input, inputSupplier: v.target.value })}
          />
        </Form.Item>
        <Form.Item label="Normalized Curvo">
          <NormalizedCurvoSuggestionsSelect
            onCreateNormalizedCurvo={normalizedCurvo => {
              setInput({ ...input, normalizedCurvo })
              if (typeRef.current) {
                typeRef.current.focus()
              }
            }}
            value={input.normalizedCurvo || ''}
            onChange={v => setInput({ ...input, normalizedCurvo: v })}
          />
        </Form.Item>
        <Form.Item label="Type">
          <Input ref={typeRef} value={input.type || ''} onChange={v => setInput({ ...input, type: v.target.value })} />
        </Form.Item>
        <Form.Item label="Produces Reprocessed Items">
          <Checkbox
            checked={!!input.producesReprocessedItems}
            onChange={e => setInput({ ...input, producesReprocessedItems: e.target.checked })}
          />
        </Form.Item>

        <Form.Item label="Manufacturer">
          <ManufacturerSelect
            value={
              input.manufacturerId
                ? {
                    key: input.manufacturerId,
                    label: normalized && normalized.manufacturer ? normalized.manufacturer.name : input.manufacturerId,
                  }
                : undefined
            }
            allowClear
            onChange={v =>
              setInput({
                ...input,
                manufacturerId: v ? (v as LabeledValue).key : null,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Retry">
          <MultipleManufacturerSelect
            value={
              input.retry
                ? (input.retry as Pick<Manufacturer, 'id' | 'name'>[]).map(retry => ({
                    key: retry.id,
                    label: retry.name,
                  }))
                : undefined
            }
            allowClear
            onChange={v =>
              setInput({
                ...input,
                retry:
                  v &&
                  (v as LabeledValue[]).map(selected => ({
                    id: selected.key,
                    name: (selected.label && selected.label.toString().replace(/.+\s\|/, '')) || '',
                  })),
              })
            }
          />
        </Form.Item>
      </EditPanelWrapper>
    </EditDrawer>
  )
}
